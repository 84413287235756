import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import { useLocationReadAndWriteURL } from 'features/location/hooks/LocationReadAndWriteURL/LocationReadAndWriteURL.hook';
import {
  useFiltersDispatch,
  useFiltersState,
} from 'features/filters/Filters.context';
import { useFilterProps } from 'components/SearchPage/features/QuickFilters/FilterProps.hook';
import { useSEOUpdates } from 'features/filters/SEOUpdates.hook';
import { useMakeModelReadAndWriteURL } from 'components/SearchPage/features/makeModel/hooks/MakeModelReadAndWriteURL.hook';
import { Filter } from 'features/filters/components/Filter/Filter';

import {
  formatPathParameters,
  getFilterPathParameterData,
} from 'helpers/seo/searchParams';
import * as Styled from 'features/filters/FiltersHOC.styled';

import type { ISearchPageFilter } from 'api/types/searchPageApiTypes';
import type { Param } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import type { MakeModelFilterProps } from 'features/filters/components/MakeModelV2/MakeModelFilter.typed';

import { MAXIMUM_AMOUNT_OF_MAKE_MODEL_GROUPS_TO_BE_DISPLAYED } from 'components/SearchPage/features/QuickFilters/Filters.constants';
import { DisplayFiltersProps } from 'features/filters/FiltersHOC.typed';
import { useAccordionState } from 'features/filters/AccordionState.hook';

import { DISPLAY_CERTIFIED_TRUSTED_DEALER_CARS_FILTER } from 'utils';
import { CertifiedTustedDealerCarsCheckbox } from './components/CertifiedTrustedDealerCarsCheckbox/CertifiedTrustedDealerCarsCheckbox';

type onChangeFilterValueArgs = {
  queryParam?: Param;
  filterGroup?: string;
  filterName?: string;
  filterValue?: string | Array<string>;
};

const DisplayFilters = (props: DisplayFiltersProps) => {
  const { sectionName } = props;
  const { filtersData } = useFiltersState();
  const { setIsFiltersMounted } = useFiltersDispatch();

  const { query } = useRouter();
  const { section } = query;
  const { currentSection, makePathParameter, modelPathParameter } =
    formatPathParameters(section);

  const {
    updateURL,
    updateMakeModels,
    areaQueryValue,
    countyTownQueryValue,
    carFilterQueryValue,
    radiusQueryValue,
  } = useURLStateManagement();

  const { handleSelect: onSelect } = useLocationReadAndWriteURL({
    areaQueryValue,
    countyTownQueryValue,
    SEOPathParam: carFilterQueryValue,
    applySEOPathParam: Boolean(makePathParameter && modelPathParameter),
    radiusQueryValue,
    URLCallback: updateURL,
  });

  const radiusCallback = (radius: string) =>
    updateURL({
      queryParams: { radius },
    });

  const locationConfiguration = {
    onSelect,
    radiusCallback,
  };

  const { containsValidPrice } = query;
  const validPriceConfiguration = {
    onSelectAdsWithValidPrice: (containsValidPriceValue: boolean) => {
      updateURL({
        queryParams: {
          containsValidPrice: containsValidPriceValue ? 'true' : undefined,
        },
      });
    },
    containsValidPrice: containsValidPrice === 'true',
  };

  const { handleSEOPathParameter, checkForSEOFilter } = useSEOUpdates();

  const onChangeFilterValue = (args: onChangeFilterValueArgs) => {
    const { queryParam, filterGroup, filterName, filterValue } = args;

    const isSEOFilter = checkForSEOFilter(filterName, formattedFilterName);

    if (isSEOFilter) {
      handleSEOPathParameter({
        filterName,
        filterGroup,
        queryParams: queryParam,
      });
    } else if (filterName === 'make' && filterValue === 'string') {
      updateMakeModels({ makeModels: [{ make: filterValue }] });
    } else {
      updateURL({ queryParams: queryParam });
    }
  };

  const {
    filterValue: SEOFilterValue,
    formattedFilterName,
    filterSearchQueryGroup,
  } = getFilterPathParameterData(filtersData, carFilterQueryValue);

  const { getFilterProps } = useFilterProps({
    onChangeFilterValue,
    filterQueryValues: {
      ...query,
      /**
       *  Check to see if filter value is applied as a path parameter
       *  rather than a query parameter
       * */
      ...(formattedFilterName ? { [formattedFilterName]: SEOFilterValue } : {}),
    },
  });

  const { onSelectMake, onSelectModel, onAddSelection } =
    useMakeModelReadAndWriteURL({
      filterName: formattedFilterName,
      updateMakeModels,
      searchQueryGroup: filterSearchQueryGroup,
      maximumAmountOfMakeModelGroupsToBeDisplayed:
        MAXIMUM_AMOUNT_OF_MAKE_MODEL_GROUPS_TO_BE_DISPLAYED,
    });

  const makeModelConfiguration: MakeModelFilterProps = {
    selectMake: onSelectMake,
    selectModel: onSelectModel,
    onAddSelection,
    maximumAmountOfMakeModelGroupsToBeDisplayed:
      MAXIMUM_AMOUNT_OF_MAKE_MODEL_GROUPS_TO_BE_DISPLAYED,
  };

  const { getAccordionState, toggleAccordionState, hasUpdatedAccordionState } =
    useAccordionState(filtersData);

  const validFilters = filtersData?.filter(
    (item: ISearchPageFilter) =>
      item.variant !== 'HIDDEN' && item.name !== 'terms',
  );

  useEffect(() => {
    setIsFiltersMounted(true);
    return () => {
      setIsFiltersMounted(false);
    };
  }, [setIsFiltersMounted]);

  return (
    <div>
      {DISPLAY_CERTIFIED_TRUSTED_DEALER_CARS_FILTER &&
        currentSection == 'cars' && <CertifiedTustedDealerCarsCheckbox />}
      {validFilters && hasUpdatedAccordionState && (
        <Styled.FilterList>
          {validFilters?.map((item: ISearchPageFilter, index: number) => {
            const { name, filterType, searchQueryGroup } = item;
            const showDivider = index !== validFilters.length - 1;

            const { onChange, value } = getFilterProps(
              filterType.name,
              name,
              searchQueryGroup,
            );
            /**
             *  Check if the filter value is actually a path parameter rather than a query parameter.
             *  In order for ranges to be path parameters, both values must be equal.
             * */
            const formattedSEOFilterValue =
              SEOFilterValue && searchQueryGroup === 'ranges'
                ? [SEOFilterValue, SEOFilterValue]
                : SEOFilterValue;

            const filterValue =
              formattedFilterName === name && formattedSEOFilterValue
                ? formattedSEOFilterValue
                : value;

            return (
              <Filter
                key={`filter-${item.name}`}
                item={item}
                locationConfiguration={locationConfiguration}
                makeModelConfiguration={makeModelConfiguration}
                validPriceConfiguration={validPriceConfiguration}
                sectionName={sectionName}
                onChange={onChange}
                filterValue={filterValue}
                toggleAccordionState={toggleAccordionState}
                isOpen={getAccordionState(item.name)}
                showDivider={showDivider}
              />
            );
          })}
        </Styled.FilterList>
      )}
    </div>
  );
};

export { DisplayFilters };
