import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import type { ReactNode } from 'react';
import getConfig from 'next/config';
import { BreadcrumbJsonLd } from 'next-seo';
import NextLink from 'next/link';
import { HUBVISOR_SCRIPT_ENABLED } from 'utils';

import { DFPSlotsProvider } from '@dsch/react-dfp';

import type { ISearchPageAds } from 'api/types/searchPageApiTypes';
import type { IAdSenseUnit } from 'types';
import type { NotificationFrequency } from 'components/SearchPage/hooks/useSavedSearch/useSavedSearch.typed';
import type { UnitProps } from 'components/Toolkit/DFP';
import { Breadcrumbs } from 'components/ToolkitV2/Breadcrumbs/Breadcrumbs';
import { cookies } from 'constants/cookies';
import { adSenseSearchStyleId } from 'helpers/constants';
import {
  AD_UNIT_TYPE,
  AdUnit,
} from 'components/SearchPage/hooks/useSearchPageAdUnits';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { useCookies } from 'hooks/UseCookies';
import { useWootric } from 'hooks/UseWootric';
import { useUserContext } from 'contexts/UserContext';
import { useSearchPageSeo } from 'components/SearchPage/hooks/useSearchPageSeo';
import { useSavedSearch } from 'components/SearchPage/hooks/useSavedSearch/useSavedSearch';
import { useSearchPageGTM } from 'components/SearchPage/hooks/useSearchPageGTM';
import { useSearchPageAdUnits } from 'components/SearchPage/hooks/useSearchPageAdUnits';
import { useAds } from 'components/SearchPage/hooks/useAds/useAds';
import { KeywordSearch } from 'components/SearchPage/components/KeywordSearch/KeywordSearch';
import { Sort } from 'components/SearchPage/components/Sort/Sort';
import { NoResults } from 'components/SearchPage/components/NoResults/NoResults';
import { SeoTags } from 'components/SearchPage/components/SeoTags/SeoTags';
import { SavedSearchModal } from 'components/PageWrappers/SharedComponents/SavedSearchModal/SavedSearchModal';
import { SavedSearchButton } from 'components/SearchPage/components/SavedSearchButton/SavedSearchButton';
import { SectionBrowserPanel } from 'components/SearchPage/components/SectionBrowser/Wrappers/SectionBrowserPanel/SectionBrowserPanel';
import { SectionBrowserDropdown } from 'components/SearchPage/components/SectionBrowser/Wrappers/SectionBrowserDropdown/SectionBrowserDropdown';
import {
  DFPUnit,
  getDFPLazyLoadConfigSearchResultsPage,
} from 'components/Toolkit/DFP/DFP';
import { Listings } from 'components/SearchPage/components/Listings/Listings';
import { ListItemFullWidth } from 'components/SearchPage/components/Listings/Listings.styled';
import { Link } from 'components/Toolkit/Button/Link';
import { FeaturedDealer } from 'components/Toolkit/FeaturedDealer/FeaturedDealer';
import { BuyingCarTips } from 'components/SearchPage/components/BuyingCarTips/BuyingCarTips';
import { Faq } from 'components/SearchPage/components/Faq/Faq';
import { RecommendedAdsCarousel } from 'components/SearchPage/components/RecommendedAdsCarousel/RecommendedAdsCarousel';
import { Pagination } from 'components/Toolkit/Pagination/Pagination';
import { SearchHead } from 'components/SearchPage/components/SearchHead/SearchHead';
import { Footer } from 'components/SearchPage/components/Footer/Footer';
import * as SearchPageLayout from 'components/SearchPage/styles';
import {
  useSearchPageDispatch,
  useSearchPageState,
} from 'components/SearchPage/context/SearchPageContext';
import { useLastSearch } from 'components/PageWrappers/SharedComponents/LastSearchCard/hooks/useLastSearch';

import { useLocationSearchTracking } from 'components/SearchPage/hooks/useLocationSearchTracking/useLocationSearchTracking';
import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import type {
  SearchPageProps,
  ViewType,
} from 'components/SearchPage/SearchPage.typed';
import { usePagination } from 'components/SearchPage/hooks/usePagination/usePagination';
import { useFiltersState } from 'features/filters/Filters.context';
import { FiltersPanel } from 'features/filters/components/FiltersPanel/FiltersPanel';

import { useRouter } from 'next/router';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';
import { QuickFilterProvider } from 'components/SearchPage/features/QuickFilters/QuickFilters.context';
import { LayoutSelector } from 'components/SearchPage/components/LayoutSelector/LayoutSelector';

import type { FiltersModalProps } from 'features/filters/components/FiltersModal/FiltersModal.typed';
import { useBreadcrumbs } from 'components/SearchPage/hooks/useBreadcrumbs/useBreadcrumbs';
import { formatSearchTerms } from 'helpers/formatSearchTerms';
import { useSavedSearchPrompt } from 'components/SearchPage/hooks/useSavedSearchPrompt/useSavedSearchPrompt';
import { DisplayFilters } from 'features/filters/FiltersHOC';
import { MakeModelProvider } from 'components/SearchPage/features/makeModel/MakeModelContext';
import { useSEOFilterValues } from 'components/SearchPage/hooks/useSEOFilterValues/useSEOFilterValues';

const DynamicFiltersModal = dynamic<FiltersModalProps>(
  import('features/filters/components/FiltersModal/FiltersModal').then(
    (mod) => mod.FiltersModal,
  ),
  { ssr: false },
);

const {
  publicRuntimeConfig: {
    CDN_STATIC_ASSETS,
    DFP_NETWORK_ID,
    AD_SENSE_PUBLIC_KEY,
  },
} = getConfig();

const SearchPage = (props: SearchPageProps) => {
  const { initialValues, notFound, trader, promoSlot } = props;
  const { query, back, asPath } = useRouter();

  const {
    currentSectionQueryValue: currentSection,
    sortQueryValue: sort,
    keywordQueryValue: keywordSearchTerm,
    paginationQueryValue,
    updateURL,
    countyTownQueryValue: countyTown,
  } = useURLStateManagement();

  const { isFilterModalOpen, sortFields, isFiltersMounted } = useFiltersState();

  const {
    viewType,
    baseUrl,
    paging,
    recommendations,
    seoData,
    dfp: dfpSettings,
    gtm: gtmData,
    ads,
    spotlights,
    isLoading,
    faq,
  } = useSearchPageState();
  const isCars = currentSection === 'cars';

  const combinedAds = [...(spotlights ?? []), ...(ads ?? [])];

  const { setViewType } = useSearchPageDispatch();

  const viewTypeCookie = useCookies({ name: cookies.VIEW_TYPE });
  const {
    user,
    consentOptions: { functionalConsent, allowWootric },
    isSavedSearchModalOpen,
    setIsSavedSearchModalOpen,
    isInstantAlertAvailable,
  } = useUserContext();

  const {
    getAdsSectionsAndFilters,
    sections,
    sectionDisplayName,
    parentName,
    parentDisplayName,
    searchMatches,
  } = useAds({
    initialSections: initialValues.sectionsData,
    initialSectionDisplayName: initialValues.sectionDisplayName,
    initialParentName: initialValues.parentName,
    initialParentDisplayName: initialValues.parentDisplayName,
    initialSearchMatches: initialValues.searchMatches,
  });

  useOnUpdateOnly(() => {
    getAdsSectionsAndFilters();
  }, [query]);

  const adUnits = useSearchPageAdUnits({
    dfpSettings,
    adSenseSettings: {
      query: keywordSearchTerm || sectionDisplayName || 'cars',
      pubId: AD_SENSE_PUBLIC_KEY,
      styleId: adSenseSearchStyleId,
    },
  });

  const gtm = useSearchPageGTM(gtmData);

  const {
    handleOnClickSave,
    createSavedSearch,
    saving: savedSearchSaving,
    savedSearchTerms,
    savedSearchName,
    setSavedSearchName,
    selected,
    setSelected,
    isSavedSearchDisabled,
  } = useSavedSearch(sectionDisplayName);

  const DFPVertical = (dfpSettings?.vertical && dfpSettings?.vertical[0]) || '';

  useWootric(
    user?.id,
    mapDFPVertical(DFPVertical),
    user?.registrationdate || '',
    allowWootric,
  );

  useLocationSearchTracking();

  const {
    wanted,
    fuelTypes,
    colours,
    transmissions,
    bodyTypes,
    years,
    makeValue,
    modelValue,
    areas,
  } = useSEOFilterValues();

  const { isMotor, h1, h2, pageTitle, metaDescription, canonical } =
    useSearchPageSeo({
      baseURL: baseUrl,
      areas,
      sectionName: sectionDisplayName,
      adTotal: paging.totalResults ?? 0,
      wanted,
      trader,
      years,
      fuels: fuelTypes,
      makes: makeValue,
      models: modelValue,
      colours,
      transmissions,
      bodyTypes,
      countyTown,
    });

  const previousSection =
    parentName && parentDisplayName
      ? {
          name: parentName,
          displayName: parentDisplayName,
        }
      : undefined;

  const { setLastSearch } = useLastSearch();

  useOnUpdateOnly(() => {
    if (functionalConsent && viewType) {
      viewTypeCookie.set({ value: viewType });
    }
  }, [viewType]);

  useOnUpdateOnly(() => {
    const from = paginationQueryValue ? Number(paginationQueryValue) : 0;

    if (Number(paginationQueryValue) < 0) {
      rg4js('send', {
        error: new Error('Negative pagination value from filter change'),
        customData: {
          start: from,
          query,
          referer: document.referrer,
          page: window.location.href,
          pageName: PAGE.SEARCH,
          functionName: 'fetch Ads',
        },
      });
    }
  }, [paginationQueryValue]);

  useEffect(() => {
    gtm.searchPageEvent({ viewType: [viewType] });

    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function mapDFPVertical(name: string) {
    switch (name) {
      case 'All': {
        return 'Marketplace';
      }
      default: {
        return name;
      }
    }
  }

  useOnUpdateOnly(() => {
    const formattedSearchTerms = formatSearchTerms(savedSearchTerms);

    setLastSearch(formattedSearchTerms, asPath);
  }, [savedSearchTerms]);

  async function handleOnSubmitSave(
    notifications: NotificationFrequency,
    name: string,
  ) {
    await createSavedSearch({
      name,
      terms: savedSearchTerms,
      notifications,
      userId: user?.id || 0,
    });
    gtm.createSavedSearchEvent(notifications);
  }

  function renderAdSenseUnit(
    item: IAdSenseUnit,
    index: number,
    hidden: boolean,
  ) {
    return (
      <ListItemFullWidth
        className={`ad-sense-${item.container}`}
        key={`ad-sense-${index}`}
        id={item.container}
        hidden={hidden}
      />
    );
  }

  function renderDFPUnit(item: UnitProps, index: number, hidden: boolean) {
    return (
      <ListItemFullWidth
        className={`dfp-${item.slotId}`}
        key={`dfp-${index}`}
        hidden={hidden}
      >
        <DFPUnit onSlotRender={adUnits.onDFPUnitLoad} {...item} />
      </ListItemFullWidth>
    );
  }

  function renderRecommendedAdsCarousel(recommendedAds: ISearchPageAds[]) {
    return (
      <ListItemFullWidth key="recommended_ads_carousel">
        <RecommendedAdsCarousel
          recommendedAds={recommendedAds}
          currentSection={currentSection}
        />
      </ListItemFullWidth>
    );
  }

  function renderListingExtraUnits(
    index: number,
    viewType: ViewType | undefined,
  ): ReactNode {
    const item = adUnits.getListingUnit(index, viewType);

    const isGrid = viewType === 'grid';

    const RECOMMENDED_ADS_LISTING_VIEW_POSITION = 11;
    const RECOMMENDED_ADS_GRID_VIEW_LISTINGS_POSITION = 12;

    const renderRecommendedAdsListingsView =
      !isGrid &&
      index === RECOMMENDED_ADS_LISTING_VIEW_POSITION &&
      recommendations &&
      recommendations.length > 0;

    const renderRecommendedAdsGridView =
      isGrid &&
      index === RECOMMENDED_ADS_GRID_VIEW_LISTINGS_POSITION &&
      recommendations &&
      recommendations.length > 0;

    if (renderRecommendedAdsListingsView) {
      return renderRecommendedAdsCarousel(recommendations);
    }

    if (renderRecommendedAdsGridView) {
      return renderRecommendedAdsCarousel(recommendations);
    }

    if (!item) {
      return [];
    }

    switch (item.type) {
      case AD_UNIT_TYPE.AD_SENSE:
        if (!isCars) {
          return renderAdSenseUnit(item.data, index, item.hidden || false);
        } else return [];
      case AD_UNIT_TYPE.DFP:
        return renderDFPUnit(item.data, index, item.hidden || false);
      default:
        return [];
    }
  }

  function renderBottomUnit(item: AdUnit) {
    switch (item.type) {
      case AD_UNIT_TYPE.AD_SENSE:
        return <div id={item.data.container}></div>;
      case AD_UNIT_TYPE.DFP:
        return <DFPUnit {...item.data} />;
      default:
        return [];
    }
  }

  const hasAds = combinedAds && combinedAds?.length > 0;

  const { formattedBreadcrumbs, breadcrumbJsonLdItems } = useBreadcrumbs();

  const showBuyingCarTips = isCars && formattedBreadcrumbs.length === 3;

  const paginationProps = usePagination();

  const { isSavedSearchPromptOpen, closeSavedSearchPrompt } =
    useSavedSearchPrompt();

  const mappedInitialMakeModels = initialValues.initialMakeModels?.map(
    (item) => {
      return {
        ...item,
        model: item.model ?? undefined,
      };
    },
  );

  return (
    <MakeModelProvider initialMakeModels={mappedInitialMakeModels}>
      <SearchHead
        currentSection={currentSection}
        h1={h1}
        title={pageTitle}
        description={metaDescription}
        url={canonical}
        isMotor={isMotor}
        ads={combinedAds}
      />
      <BreadcrumbJsonLd itemListElements={breadcrumbJsonLdItems} />
      <SearchPageLayout.Header>
        <div className="row">
          <div className="columns small-12">
            <Breadcrumbs breadcrumbs={formattedBreadcrumbs} goBack={back} />
            <SearchPageLayout.HeaderDivider>
              <SearchPageLayout.HeaderTitle>{h1}</SearchPageLayout.HeaderTitle>
              <SearchPageLayout.HeaderSearch>
                <KeywordSearch sectionName={sectionDisplayName} />
              </SearchPageLayout.HeaderSearch>
              {!!sections?.length && (
                <SearchPageLayout.HeaderSections data-testid="section-dropdown">
                  <SectionBrowserDropdown
                    currentSection={{
                      name: currentSection,
                      displayName: sectionDisplayName,
                      searchMatches,
                    }}
                    sections={sections}
                    parentSection={previousSection}
                  />
                </SearchPageLayout.HeaderSections>
              )}
            </SearchPageLayout.HeaderDivider>
          </div>
        </div>
      </SearchPageLayout.Header>

      <QuickFilterProvider>
        <SearchPageLayout.MobileQuickFilters
          className="common-margin-bottom"
          sectionName={sectionDisplayName}
        />
      </QuickFilterProvider>

      <DFPSlotsProvider
        dfpNetworkId={DFP_NETWORK_ID}
        {...(HUBVISOR_SCRIPT_ENABLED
          ? {}
          : getDFPLazyLoadConfigSearchResultsPage())}
      >
        <div className="row">
          <div className="columns small-12">
            <SearchPageLayout.DFPUnitContainer_Top>
              <DFPUnit {...adUnits.getTopUnit()} />
            </SearchPageLayout.DFPUnitContainer_Top>
          </div>
        </div>
        <div className="row">
          <div className="columns small-12">
            <SearchPageLayout.Main>
              <SearchPageLayout.Sidebar>
                <SavedSearchButton
                  disabled={isSavedSearchDisabled}
                  onClick={handleOnClickSave}
                  isSavedSearchPromptOpen={isSavedSearchPromptOpen}
                  closeSavedSearchPrompt={closeSavedSearchPrompt}
                />
                &nbsp;
                <FiltersPanel>
                  {!!sections?.length && (
                    <SectionBrowserPanel
                      currentSection={{
                        name: currentSection,
                        displayName: sectionDisplayName,
                        searchMatches,
                      }}
                      sections={sections}
                      parentSection={previousSection}
                    />
                  )}
                  {!isFilterModalOpen && (
                    <DisplayFilters sectionName={sectionDisplayName} />
                  )}
                </FiltersPanel>
                {/* The FiltersPanel has a dynamic height, initially loading this unit near the top.  
                    Once search filters load, they push the ad unit down, making the skyscraper ad visible twice.
                    It's visile on page load, then again as the user scrolls down blow the filters.  
                    To prevent this, we load the ad unit after the filters have loaded. 
                */}
                {isFiltersMounted && (
                  <SearchPageLayout.SidebarAdUnit>
                    <DFPUnit {...adUnits.getSidebarUnit()} />
                  </SearchPageLayout.SidebarAdUnit>
                )}
              </SearchPageLayout.Sidebar>
              <SearchPageLayout.Body>
                <SearchPageLayout.DetailsAndToggles>
                  <SearchPageLayout.Details data-testid="h2-details-text">
                    {h2}
                  </SearchPageLayout.Details>
                  {combinedAds?.length ? (
                    <SearchPageLayout.Toggles>
                      <LayoutSelector
                        viewType={viewType}
                        setViewType={setViewType}
                      />
                      <div>
                        <Sort
                          updateURL={updateURL}
                          selected={sort}
                          sortFields={sortFields}
                        />
                      </div>
                    </SearchPageLayout.Toggles>
                  ) : null}
                </SearchPageLayout.DetailsAndToggles>
                {notFound && (
                  <NoResults
                    headerText="Sorry, this ad is no longer available"
                    subText="View similar ads below"
                    imageSrc={`${CDN_STATIC_ASSETS}/images/illustrations/ad-review.svg`}
                  />
                )}
                {hasAds ? (
                  <>
                    <Listings
                      listingsV2={combinedAds}
                      currentSection={currentSection}
                      createSavedAdEvent={gtm.createSavedAdEvent}
                      deleteSavedAdEvent={gtm.deleteSavedAdEvent}
                      isLoading={isLoading}
                      viewType={viewType}
                      extra={(index) => [
                        renderListingExtraUnits(index, viewType),
                      ]}
                    />
                    {paging?.totalPages > 1 && (
                      <SearchPageLayout.Pagination>
                        <Pagination {...paginationProps} />
                      </SearchPageLayout.Pagination>
                    )}
                  </>
                ) : (
                  <NoResults
                    headerText="Sorry, we couldn't find what you were looking for"
                    subText={
                      <>
                        Make sure any search words are spelled correctly or
                        search&nbsp;
                        <Link
                          href={`/all?words=${keywordSearchTerm}`}
                          ofType="SECONDARY"
                          inline
                          NextLink={NextLink}
                        >
                          All Sections
                        </Link>
                      </>
                    }
                  />
                )}

                {!!seoData?.internalLinks?.length &&
                  seoData?.type !== 'popular' && (
                    <SearchPageLayout.InternalTagsPanel>
                      <SearchPageLayout.InternalTagsTitle>
                        You may also be interested in
                      </SearchPageLayout.InternalTagsTitle>
                      <SeoTags items={seoData?.internalLinks} />
                    </SearchPageLayout.InternalTagsPanel>
                  )}

                {renderBottomUnit(adUnits.getBottomUnit())}
              </SearchPageLayout.Body>
            </SearchPageLayout.Main>
          </div>
        </div>
      </DFPSlotsProvider>
      {showBuyingCarTips && (
        <SearchPageLayout.BuyingCarTipsContainer>
          <div className="row small-collapse medium-uncollapse">
            <div className="columns small-12">
              <BuyingCarTips
                baseUrl={baseUrl}
                blogUrl="https://blog.donedeal.ie"
                youtubeUrl="https://www.youtube.com/channel/UCNYw8CUPcrAEy9TbHV2Pr5A"
              />
            </div>
          </div>
        </SearchPageLayout.BuyingCarTipsContainer>
      )}

      {!!seoData?.internalLinks?.length && seoData?.type === 'popular' && (
        <SearchPageLayout.InternalLinksContainer isWhite={!isCars}>
          <div className="row small-collapse medium-uncollapse">
            <div className="columns small-12">
              <SearchPageLayout.SInternalLinks
                title="You may also be interested in"
                links={seoData.internalLinks}
              />
            </div>
          </div>
        </SearchPageLayout.InternalLinksContainer>
      )}

      {faq && <Faq {...faq} />}

      {promoSlot && (
        <FeaturedDealer
          heading="Featured Dealer"
          domain={baseUrl}
          cdnUrl={CDN_STATIC_ASSETS}
          dealerLogo={promoSlot.largeLogoUrl ?? promoSlot.logoUrl}
          title={promoSlot.display}
          listItems={[
            promoSlot.dealerType,
            `Total Stock: ${promoSlot.totalAdCount} Ads`,
          ]}
          showRoomUrl={promoSlot.showroomUrl}
          showRoomText="See Showroom"
          ads={promoSlot.ads}
          testId="featured-dealer-list"
        />
      )}
      <Footer
        isSavedSearchDisabled={isSavedSearchDisabled}
        handleOnClickSave={handleOnClickSave}
        isSavedSearchPromptOpen={isSavedSearchPromptOpen}
        closeSavedSearchPrompt={closeSavedSearchPrompt}
      />
      <DynamicFiltersModal
        adCount={paging?.totalResults ?? 0}
        sectionName={sectionDisplayName}
      />
      <SavedSearchModal
        saving={savedSearchSaving}
        name={savedSearchName}
        terms={savedSearchTerms}
        isOpen={isSavedSearchModalOpen}
        askToClose={() => setIsSavedSearchModalOpen(false)}
        onSave={handleOnSubmitSave}
        isInstantAlertAvailable={isInstantAlertAvailable}
        setName={setSavedSearchName}
        selected={selected}
        setSelected={setSelected}
      />
    </MakeModelProvider>
  );
};

export { SearchPage };
