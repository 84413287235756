import styled from 'styled-components';

const Container = styled.div`
  position: sticky;
  z-index: ${({ theme }) => theme.zIndices.STICKY_BUTTON};
  left: 0;
  right: 0;
  bottom: 0;
`;

export { Container };
