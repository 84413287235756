import styled from 'styled-components';

import { Label } from 'components/Toolkit/Inputs/Label';
import { SimpleDropdown } from 'components/Toolkit/SimpleDropdown/SimpleDropdown';
import { Menu } from 'components/Toolkit/Menu/Menu';
import { removeNonNumericCharacters } from 'helpers/forms';
import { Select } from 'components/Toolkit/Inputs/Select';
import { ListSingleSelectTag } from 'features/filters/components/ListSingleSelectTag/ListSingleSelectTag';
import { DropDownRangeContainer } from 'features/filters/components/DropDownRange/DropDownRange';

import {
  mapToOptionsRanges,
  mapFromOptionsRanges,
} from 'features/filters/Filters.mapper';

import type { TypePrice } from 'api/types/searchPageApiTypes';
import type { StrictOption } from 'components/Toolkit/Inputs/Select';

import { CURRENCIES } from 'types';
import type { HeightVariant } from 'components/Toolkit/Inputs/types';
import { useState } from 'react';

type DropDownValue = {
  options: StrictOption[];
  selectedValue: string;
};

export interface IPriceSelectRangeProps {
  value?: {
    from: string | undefined;
    to: string | undefined;
    currency: CURRENCIES | undefined;
    typePrice: TypePrice | undefined;
  };
  typePrice: TypePrice;
  onChangePrice: (
    currency: CURRENCIES,
    from: string | undefined,
    to: string | undefined,
    type: TypePrice,
  ) => void;
  onChangeType: (currency: CURRENCIES, type: TypePrice) => void;
  from: DropDownValue;
  to: DropDownValue;
  fromMonth: DropDownValue;
  toMonth: DropDownValue;
  label?: string | null;
  showCurrencySelectorInTitle?: boolean;
  willUseSubText?: boolean;
  heightVariant?: HeightVariant;
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RangeHeadingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type PriceCurrency = {
  value: CURRENCIES;
  displayName: string;
  symbol: string;
};

const euro: PriceCurrency = {
  value: CURRENCIES.EUR,
  displayName: '€ EUR',
  symbol: '€',
};

const pound: PriceCurrency = {
  value: CURRENCIES.GBP,
  displayName: '£ GBP',
  symbol: '£',
};

const PriceSelectMonthRange = (props: IPriceSelectRangeProps) => {
  const {
    value,
    onChangePrice,
    typePrice,
    from,
    to,
    fromMonth,
    toMonth,
    onChangeType,
    label = 'Price',
    showCurrencySelectorInTitle = true,
    willUseSubText = true,
    heightVariant,
  } = props;

  /* In the absence of a persistent query value to determine currency, we use state to
   * to keep track of a user's choice */
  const [currency, setCurrency] = useState(value?.currency ?? CURRENCIES.EUR);
  const fromValue = value?.from;
  const toValue = value?.to;
  const priceCurrency = currency === CURRENCIES.GBP ? pound : euro;

  const handleOnCurrencyChange = (
    currency: CURRENCIES,
    typePrice: TypePrice,
  ) => {
    onChangePrice(currency, fromValue, toValue, typePrice);
    setCurrency(currency);
  };

  const isPricePerMonth = typePrice === 'pricePerMonth';

  const handleTypeUpdate = (newValue: TypePrice) => {
    onChangeType(currency, newValue);
    if (newValue === 'pricePerMonth') {
      setCurrency(CURRENCIES.EUR);
    }
  };

  const currencySelector = (
    <SimpleDropdown
      isDisabled={isPricePerMonth}
      text={priceCurrency.displayName}
      variant="PRIMARY"
    >
      <Menu
        items={[
          {
            displayName: euro.displayName,
            onClick: () => handleOnCurrencyChange(euro.value, typePrice),
          },
          {
            displayName: pound.displayName,
            onClick: () => handleOnCurrencyChange(pound.value, typePrice),
          },
        ]}
      />
    </SimpleDropdown>
  );

  return (
    <div>
      <HeaderContainer>
        {label && <Label htmlFor="search-price-filter-for">{label}</Label>}
        {showCurrencySelectorInTitle && currencySelector}
      </HeaderContainer>
      <ListSingleSelectTag
        name="type_price"
        label=""
        options={[
          { value: 'price', label: 'Total Price' },
          { value: 'pricePerMonth', label: 'Per month' },
        ]}
        value={typePrice}
        onChange={handleTypeUpdate}
        heightVariant={heightVariant}
      />
      {!showCurrencySelectorInTitle && (
        <RangeHeadingContainer>{currencySelector}</RangeHeadingContainer>
      )}
      {isPricePerMonth ? (
        <DropDownRangeContainer>
          <Select
            onChange={(fromMonth) => {
              onChangePrice(
                priceCurrency.value,
                removeNonNumericCharacters(fromMonth),
                toValue,
                typePrice,
              );
            }}
            name={'price_month_range_from'}
            value={
              fromMonth && fromMonth.selectedValue && fromMonth.selectedValue
            }
            options={fromMonth && mapFromOptionsRanges(fromMonth, toMonth)}
            willUseSubText={willUseSubText}
            heightVariant={heightVariant}
          />
          <Select
            onChange={(toMonth) => {
              onChangePrice(
                priceCurrency.value,
                fromValue,
                removeNonNumericCharacters(toMonth),
                typePrice,
              );
            }}
            name={'price_month_range_to'}
            value={toMonth && toMonth.selectedValue && toMonth.selectedValue}
            options={toMonth && mapToOptionsRanges(fromMonth, toMonth)}
            willUseSubText={willUseSubText}
            heightVariant={heightVariant}
          />
        </DropDownRangeContainer>
      ) : (
        <DropDownRangeContainer>
          <Select
            onChange={(from) => {
              onChangePrice(
                priceCurrency.value,
                removeNonNumericCharacters(from),
                toValue,
                typePrice,
              );
            }}
            name={'price_range_from'}
            value={from && from.selectedValue && from.selectedValue}
            options={from && mapFromOptionsRanges(from, to)}
            willUseSubText={willUseSubText}
            heightVariant={heightVariant}
            placeholderAsOption={true}
          />
          <Select
            onChange={(to) => {
              onChangePrice(
                priceCurrency.value,
                fromValue,
                removeNonNumericCharacters(to),
                typePrice,
              );
            }}
            name={'price_range_to'}
            value={to && to.selectedValue && to.selectedValue}
            options={to && mapToOptionsRanges(from, to)}
            willUseSubText={willUseSubText}
            heightVariant={heightVariant}
            placeholderAsOption={true}
          />
        </DropDownRangeContainer>
      )}
    </div>
  );
};

export { PriceSelectMonthRange };
