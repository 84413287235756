import styled from 'styled-components';

import { Label } from 'components/Toolkit/Inputs/Label';
import { SimpleDropdown } from 'components/Toolkit/SimpleDropdown/SimpleDropdown';
import { Menu } from 'components/Toolkit/Menu/Menu';
import { removeNonNumericCharacters } from 'helpers/forms';

import { CURRENCIES } from 'types';
import { Select, StrictOption } from 'components/Toolkit/Inputs/Select';
import {
  mapToOptionsRanges,
  mapFromOptionsRanges,
} from 'features/filters/Filters.mapper';
import type { HeightVariant } from 'components/Toolkit/Inputs/types';
import { useState } from 'react';

export type DropDownValue = {
  options: StrictOption[];
  selectedValue: string;
};

export interface IPriceSelectRangeProps {
  value?: {
    from: string | undefined;
    to: string | undefined;
    currency: CURRENCIES | undefined;
  };
  onChangePrice: (
    currency: CURRENCIES,
    from: string | undefined,
    to: string | undefined,
  ) => void;

  from: DropDownValue;
  to: DropDownValue;
  label?: string | null;
  willUseSubText?: boolean;
  heightVariant?: HeightVariant;
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing.S12};
`;

type PriceCurrency = {
  value: CURRENCIES;
  displayName: string;
  symbol: string;
};

const euro: PriceCurrency = {
  value: CURRENCIES.EUR,
  displayName: '€ EUR',
  symbol: '€',
};

const pound: PriceCurrency = {
  value: CURRENCIES.GBP,
  displayName: '£ GBP',
  symbol: '£',
};

const PriceSelectRange = (props: IPriceSelectRangeProps) => {
  const {
    value,
    onChangePrice,
    from,
    to,
    label = 'Price',
    willUseSubText = true,
    heightVariant,
  } = props;

  const fromValue = value?.from;
  const toValue = value?.to;

  /* In the absence of a persistent query value to determine currency, we use state to
   * to keep track of a user's choice */
  const [currency, setCurrency] = useState(value?.currency ?? CURRENCIES.EUR);

  const priceCurrency = currency === CURRENCIES.GBP ? pound : euro;

  const handleOnCurrencyChange = (currency: CURRENCIES) => {
    onChangePrice(currency, fromValue, toValue);
    setCurrency(currency);
  };

  return (
    <div>
      <HeaderContainer>
        <Label htmlFor="search-price-filter-for">{label}</Label>
        <SimpleDropdown text={priceCurrency.displayName} variant="PRIMARY">
          <Menu
            items={[
              {
                displayName: euro.displayName,
                onClick: () => handleOnCurrencyChange(euro.value),
              },
              {
                displayName: pound.displayName,
                onClick: () => handleOnCurrencyChange(pound.value),
              },
            ]}
          />
        </SimpleDropdown>
      </HeaderContainer>
      <InputContainer>
        <Select
          onChange={(from) => {
            onChangePrice(
              priceCurrency.value,
              removeNonNumericCharacters(from),
              toValue,
            );
          }}
          name={'price_range_from'}
          value={from && from.selectedValue && from.selectedValue}
          options={from && mapFromOptionsRanges(from, to)}
          willUseSubText={willUseSubText}
          heightVariant={heightVariant}
        />
        <Select
          onChange={(to) => {
            onChangePrice(
              priceCurrency.value,
              fromValue,
              removeNonNumericCharacters(to),
            );
          }}
          name={'price_range_to'}
          value={to && to.selectedValue && to.selectedValue}
          options={to && mapToOptionsRanges(from, to)}
          willUseSubText={willUseSubText}
          heightVariant={heightVariant}
        />
      </InputContainer>
    </div>
  );
};

export { PriceSelectRange };
