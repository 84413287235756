import type { NextApiRequest, NextApiResponse } from 'next';
import NextAuth, { User, NextAuthOptions } from 'next-auth';
import type { JWT } from 'next-auth/jwt';
import KeycloakProvider from 'next-auth/providers/keycloak';
import { getCurrentTimeInSeconds } from 'utils/Time';
import { PAGE } from 'helpers/pages';
import { formatServerError } from 'helpers/logger';

const buildRefreshTokenRequest = (token: JWT) => {
  const details = {
    client_id: process.env.OIDC_CLIENT,
    client_secret: process.env.OIDC_CLIENT_SECRET,
    grant_type: 'refresh_token',
    refresh_token: token.refreshToken,
  };

  const formBody: string[] = [];

  Object.entries(details).forEach(([key, value]: [string, string]) => {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(value);
    formBody.push(encodedKey + '=' + encodedValue);
  });

  const formData = formBody.join('&');

  const url = `${process.env.OIDC_URL}/protocol/openid-connect/token`;

  return { formData, url };
};

/**
 * Takes a token, and returns a new token with updated
 * `accessToken` and `accessTokenExpires`. If an error occurs,
 * returns the old token and an error property
 */
/**
 * @param  {JWT} token
 */
const refreshAccessToken = async (token: JWT) => {
  try {
    const { formData, url } = buildRefreshTokenRequest(token);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: formData,
    });

    const refreshedTokens = await response.json();

    const currentTimeInSeconds = getCurrentTimeInSeconds();

    /*
     * If the refresh token has expired, preventing the access token from being refreshed
     * and the session continuing, we add an error field to the token, triggering a logout
     */
    if (refreshedTokens.refresh_expires_in <= 0) {
      return {
        ...token,
        error: 'RefreshAccessTokenError' as const,
      };
    }

    return {
      ...token,
      accessToken: refreshedTokens.access_token,
      expiresAt: currentTimeInSeconds + refreshedTokens.expires_in,
      idToken: refreshedTokens.id_token,
      refreshToken: refreshedTokens.refresh_token ?? token.refreshToken,
    };
  } catch (error) {
    console.error(
      formatServerError({
        route: PAGE.AUTHENTICATION,
        message: 'Something went wrong attempting to refresh the tokens',
        error,
      }),
    );
    return {
      ...token,
      error: 'RefreshAccessTokenError' as const,
    };
  }
};

export const authOptions: NextAuthOptions = {
  // Configure keycloak as the auth provider
  providers: [
    KeycloakProvider({
      clientId: process.env.OIDC_CLIENT || '',
      clientSecret: process.env.OIDC_CLIENT_SECRET || '',
      issuer: process.env.OIDC_URL,
      authorization: { params: { scope: process.env.OIDC_SCOPE || '' } },
    }),
  ],
  session: {
    strategy: 'jwt',
    maxAge: 31 * 24 * 60 * 60, // 31 days,
  },
  callbacks: {
    async jwt({ token, account, user }) {
      const currentTimeInSeconds = getCurrentTimeInSeconds();

      const ACCESS_TOKEN_LIFESPAN_IN_SECONDS = 300;

      // Account and user are returned on initial call (i.e. on sign-in) only
      if (account && user) {
        /* If the time on the user's device is not in sync with the time on the server, 
        we refresh the access token immediately which will make the expiry time relative
        to the time on the user's device.
        */
        const expiryTime =
          typeof account?.expires_at === 'number' &&
          account.expires_at - currentTimeInSeconds <=
            ACCESS_TOKEN_LIFESPAN_IN_SECONDS &&
          account.expires_at - currentTimeInSeconds > 0
            ? account.expires_at
            : 0;

        token.accessToken = account.access_token || '';
        token.refreshToken = account?.refresh_token || '';
        token.idToken = account?.id_token || '';
        token.expiresAt = expiryTime;
        token.picture = '';
        token.id = user.id;
      }

      const timeRemainingToRefresh = Math.round(
        token.expiresAt - currentTimeInSeconds,
      );

      if (timeRemainingToRefresh > 0) {
        return token;
      }

      return refreshAccessToken(token);
    },
    async session({ session, token }) {
      if (token.error) {
        session.error = token.error;
        return session;
      }

      session.accessToken = token.accessToken;
      session.idToken = token.idToken;
      session.expiresAt = token.expiresAt;
      session.user = {
        name: token.name,
        email: token.email,
        image: token.picture,
      };

      return session;
    },
  },
  pages: {
    signIn: '/signin',
  },
  debug: process.env.NEXTAUTH_DEBUG === 'true' ? true : false,
};

const fn = (req: NextApiRequest, res: NextApiResponse<User | null>) => {
  return NextAuth(req, res, authOptions);
};
export default fn;
