import styled from 'styled-components';
import { Select, StrictOption } from 'components/Toolkit/Inputs/Select';
import { Label } from 'components/Toolkit/Inputs/Label';
import {
  mapToOptionsRanges,
  mapFromOptionsRanges,
} from 'features/filters/Filters.mapper';
import { HeightVariant } from 'components/Toolkit/Inputs/types';

type DropDownValue = {
  options: StrictOption[];
  selectedValue: string;
};

export interface IDropDownRange {
  label?: string;
  name: string;
  from: DropDownValue;
  to: DropDownValue;
  onFromChange: (from: string | undefined) => void;
  onToChange: (to: string | undefined) => void;
  heightVariant?: HeightVariant;
  willUseSubText?: boolean;
}

export const DropDownRangeContainer = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing.S8};
  grid-template-columns: 1fr 1fr;

  select {
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

function DropDownRange({
  label,
  name,
  from,
  to,
  onFromChange,
  onToChange,
  heightVariant = 'DEFAULT',
  willUseSubText = true,
}: IDropDownRange) {
  return (
    <div>
      {label && <Label htmlFor="search-price-filter-for">{label}</Label>}
      <DropDownRangeContainer>
        <Select
          onChange={onFromChange}
          name={`from-${name}`}
          value={from.selectedValue}
          options={from && mapFromOptionsRanges(from, to)}
          heightVariant={heightVariant}
          willUseSubText={willUseSubText}
          placeholderAsOption={true}
        />
        <Select
          onChange={onToChange}
          name={`to-${name}`}
          value={to.selectedValue}
          options={to && mapToOptionsRanges(from, to)}
          heightVariant={heightVariant}
          willUseSubText={willUseSubText}
          placeholderAsOption={true}
        />
      </DropDownRangeContainer>
    </div>
  );
}

export { DropDownRange };
