import { useRef, Fragment } from 'react';
import { useCombobox } from 'downshift';

import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import type { Options } from 'components/SearchPage/features/makeModel/MakeModel.typed';
import type { HeightVariant } from 'components/Toolkit/Inputs/types';
import { Checkbox } from 'components/Toolkit/Inputs/Checkbox';
import { Button } from 'components/Toolkit/Button';
import {
  SearchableDropdown,
  highlight,
} from 'components/ToolkitV2/SearchableDropdown/SearchableDropdown';
import { useMakeModelContext } from '../MakeModelContext';
import * as Styled from 'components/SearchPage/features/makeModel/MakeModel.styled';

type ModelFilterProps = {
  value: string;
  heightVariant?: HeightVariant;
  selected: string[];
  placeholder: string;
  showLabel?: boolean;
  showFooter?: boolean;
  isDisabled?: boolean;
  maxHeight?: string;
  addModel: (item: string) => void;
  clearModels: () => void;
};

// downshift expects a flat array of items, so we need to flatten the options
const flattenModelOptions = (
  options: Options[],
  // if true, groups will not exist. Needed as while searching we don't want to show group headings
  noGroup = false,
): ListItem[] => {
  return options.flatMap(({ title, items }) =>
    items.map(({ displayName, value }, index) => ({
      value,
      displayName,
      group: noGroup ? undefined : (index === 0 && title) || undefined,
    })),
  );
};

const ModelFilter = (props: ModelFilterProps) => {
  const {
    value: modelValue,
    heightVariant,
    showLabel = false,
    showFooter = false,
    selected,
    placeholder,
    addModel,
    clearModels,
    isDisabled,
    maxHeight,
  } = props;

  /**
   * Regular usage of downshift involves creating state initialised with options:
   * ```
      const [items, setItems] = useState(options);
      const { ... } = useCombobox({ 
        items, 
        onInputValueChange: ({ inputValue }) => setItems(options.filter(...)),
        ... 
      });
   * ```
   * This does not work here as `options` exist/change conditionally based on the make filter selection, 
   * and `useComboBox` will not automatically reinitialise it's internal state based on `options` changing.
   * 
   * To achieve desired behaviour the [modelItems, setModelItems] state is defined alongside `modelOptions` in the context.
   * Calls to `setModelOptions` will also set `modelItems`, keeping them in sync.
   * The `onInputValueChange` callback calls `setModelItems` with the filtered options.
   */
  const { modelOptions, modelItems, setModelItems } = useMakeModelContext();

  // This is the unfiltered list used when initially displaying the options (with groups)
  const items = flattenModelOptions(modelOptions || []);
  // Once a user starts typing, we only filter the 'All Model' group
  const justAllModels = flattenModelOptions(
    modelOptions?.filter((option) => option.title === 'All Models') || [],
    // Group heading is not shown when searching
    true,
  );

  const isChecked = (item: string) => {
    return selected.includes(item);
  };

  const toggleButtonRef = useRef<HTMLButtonElement>(null);
  const resetItems = () => setModelItems(items);

  const {
    isOpen,
    inputValue: value,
    highlightedIndex,
    getToggleButtonProps,
    getLabelProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    setInputValue,
    reset,
  } = useCombobox({
    // On desktop, we show a label and default to closed.
    // On mobile, the `isOpen` behaviour is controlled so this property has no effect.
    defaultIsOpen: false,
    items: modelItems || [],
    // Defining `isOpen` opts for controlled behaviour.
    // `isOpen` changing to true internally sets the focus on the input, leading to keyboard opening UX issues.
    // This is avoided by controlling the state on mobile, setting it to false but visually displaying the dropdown.
    isOpen: !showLabel ? false : undefined,
    // Triggered when user types in the input
    onInputValueChange: ({ inputValue }) => {
      // Ensures when input is cleared the original options with groups are shown
      if (!inputValue) resetItems();
      else {
        setModelItems(
          justAllModels?.filter((item) =>
            item.displayName.toLowerCase().startsWith(inputValue.toLowerCase()),
          ),
        );
      }
    },
    // Tells downshift how to get the display value of an item, as each item is an object
    itemToString: (item) => (item ? item.displayName : ''),
    onIsOpenChange: ({ isOpen }) => {
      if (!isOpen) {
        // This clears the input value
        reset();
        // This resets the items to the original list
        resetItems();
      }
    },
    // This is necessary to ensure the highlighted item is always in view
    scrollIntoView: (node) => node?.scrollIntoView({ block: 'nearest' }),
    // Why not use the `onSelectedItemChange` callback to handle onClick?
    // By default, downshift does not allow for toggle select behaviour.
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          // Triggered when user selects an item
          if (selectedItem) addModel(selectedItem.value);
          break;
        default:
          break;
      }
    },
    // For a given action, we can modify the state changes
    stateReducer(_state, actionAndChanges) {
      const { type, changes } = actionAndChanges;
      switch (type) {
        // Without this, the dropdown would close when the searchbox is clicked.
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...changes,
            isOpen: true,
          };
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter: {
          return {
            ...changes,
            // Keeps the highlighted index as the selected item even when search is cleared
            highlightedIndex: items?.findIndex(
              (item) => item.value === changes.inputValue,
            ),
            inputValue: '',
            isOpen: true,
          };
        }
        // Without this, the focus is lost when an item is selected/dropdown is closed.
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          toggleButtonRef.current?.focus();
          return changes;
        default:
          return changes;
      }
    },
  });

  return (
    <>
      {showLabel && (
        <Styled.SInputButton
          value={modelValue}
          heightVariant={heightVariant}
          isActive={isOpen}
          isDisabled={isDisabled}
          {...getToggleButtonProps({
            placeholder,
            // Passing ref from outside allows for programmatic focusing
            ref: toggleButtonRef,
            // tabIndex is needed to allow the button to be focusable, defaults to "-1"
            tabIndex: 0,
            'aria-disabled': isDisabled,
          })}
        />
      )}
      {/* On mobile showLabel is false and the SearchableDropdown is always visible */}
      <SearchableDropdown isOpen={!showLabel || isOpen}>
        <label {...getLabelProps()}>
          <SearchableDropdown.Searchbox
            placeholder={placeholder}
            onClear={() => setInputValue('')}
            {...getInputProps()}
            dataTestId="model-dropdown-input"
          />
        </label>
        <SearchableDropdown.List
          noResults={modelItems && items.length === 0}
          maxHeight={maxHeight}
          {...getMenuProps()}
        >
          {modelItems?.map((option, index) => (
            <Fragment key={`${option.group}-${index}`}>
              {option.group && (
                <SearchableDropdown.ListGroup>
                  {option.group}
                </SearchableDropdown.ListGroup>
              )}
              <SearchableDropdown.ListItem
                highlighted={highlightedIndex === index}
                dataTestId="searchable-dropdown-listitem"
                {...getItemProps({
                  item: option,
                  key: option.value,
                  index,
                })}
                key={option.value}
              >
                <Styled.ModelOption>
                  {highlight({
                    item: option.displayName,
                    searchText: value,
                  })}
                  <span>
                    <Checkbox
                      willUseSubText={false}
                      checked={isChecked(option.value)}
                      // TODO: This is a hack
                      // - On mobile stopping event propagation is needed to prevent the listItem onClick triggering twice.
                      // - On desktop, propagation is needed to keep the dropdown open after selecting item.
                      // To correctly handle this, a new checkbox component is needed that:
                      // 1. Does not internally wrap the checkbox with a label.
                      // 2. Does not control the focus state internally.
                      onClick={(e) => {
                        if (showLabel) e.stopPropagation();
                      }}
                    />
                  </span>
                </Styled.ModelOption>
              </SearchableDropdown.ListItem>
            </Fragment>
          ))}
        </SearchableDropdown.List>
        {showFooter && (
          <Styled.ButtonContainer>
            <Button ofType="SECONDARY" onClick={() => clearModels()}>
              Clear
            </Button>
            <Button
              {...getToggleButtonProps({
                // tabIndex is needed to allow the button to be focusable, defaults to "-1"
                tabIndex: 0,
              })}
            >
              Done
            </Button>
          </Styled.ButtonContainer>
        )}
      </SearchableDropdown>
    </>
  );
};

export { ModelFilter };
