import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  GetServerSidePropsResult,
  Redirect,
} from 'next';
import { Session, getServerSession } from 'next-auth';
import getConfig from 'next/config';
import { authOptions } from 'pages/api/auth/[...nextauth]';
import { ParsedUrlQuery } from 'querystring';
import { getTLD } from 'helpers/domain';
import { mockSession } from 'api/api-utils/__mocks__';
import { formatServerError } from 'helpers/logger';
import { PAGE } from 'helpers/pages';

const {
  publicRuntimeConfig: { BASE_URL },
} = getConfig();

/**
 * This function will correctly format the Authorization header with
 * @param token the authentication token
 * @returns the formatted Authorization header
 */
const setAuthorizationHeader = (token: string) => {
  return `Bearer ${token}`;
};

export type RedirectResponse = { redirect: Redirect };

const redirect = (destination: string): RedirectResponse => {
  return {
    redirect: {
      destination,
      permanent: false,
    },
  };
};

type AuthGuard = <P extends { [key: string]: any }, Q extends ParsedUrlQuery>(
  cb: (
    context: GetServerSidePropsContext<Q>,
    session?: Session,
  ) => Promise<GetServerSidePropsResult<P>>,
) => GetServerSideProps<P, Q>;

// getServerSideProps function decorator
const authGuard: AuthGuard = (callback) => async (context) => {
  if (process.env.INTEGRATION_TESTING) {
    return await callback(context, mockSession);
  }

  const tld = getTLD(context.req);
  const baseUrl = `${BASE_URL}${tld}`;

  try {
    const session = await getServerSession(
      context.req,
      context.res,
      authOptions,
    );

    const isLoggedInKeycloakUser = !!session;
    // if logged in with keycloak
    if (isLoggedInKeycloakUser) {
      const serverSidePropsResult = await callback(context, session);
      return serverSidePropsResult;
    }

    const redirectUrl = `${baseUrl}/signin?callbackUrl=${context.req.url}`;
    return redirect(`/signin?callbackUrl=${redirectUrl}`);
  } catch (error) {
    console.error(
      formatServerError({
        error,
        route: PAGE.GSSP_WRAPPER,
        message: 'Failed to get server session',
      }),
    );
    return redirect(baseUrl);
  }
};

export { authGuard, setAuthorizationHeader };
